<template>
  <div>
    <router-link :to="{ name: 'car.details', params: { carId: task_car.id }}">
      <i class="fas fa-car-alt"></i>
      <strong class="text-black">
        {{ task_car.title }}
      </strong>
      <span class="font-medium">
        (ID {{ task_car.id }})
      </span>

      <template v-if="task_car.is_deleted || task_car.sold">
        <br/>
        <label v-if="task_car.is_deleted" class="label label-default text-white font-small-xs font-medium">
          В архиве
        </label>
        <label v-if="task_car.sold" class="label label-success text-white font-small-xs font-medium ml-1">
          Продано
        </label>
      </template>
    </router-link>

    <hr class="mt-1 mb-1" />
  </div>
</template>

<script>
export default {
  name: "task-item-car-block",

  props: {
    task_car: {
      type: Object,
    },
  },
};
</script>

<style scoped>
a:hover {
  opacity: 0.8;
}
</style>
